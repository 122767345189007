<template>
  <div class="body">
    <van-row class="card-container" justify="space-around">
      <van-col span="11">
        <div
          class="card"
          :style="{ backgroundImage: 'url(' + rightImgUrl + ')' }"
        >
          <div class="card-info">
            <p class="title1">我的券夹</p>
            <p class="title2">
              <a @click="openCouponList">查看更多 ></a>
            </p>
          </div>
        </div>
      </van-col>
      <van-col span="11">
        <div
          class="card"
          :style="{ backgroundImage: 'url(' + leftImgUrl + ')' }"
        >
          <div class="card-info">
            <p class="title1">我的订单</p>
            <p class="title2" @click="openOrderList">查看全部订单 ></p>
          </div>
        </div>
      </van-col>
    </van-row>
    <van-popup
      v-model:show="state.showCoupon"
      round
      position="bottom"
      style="height: 90%; padding-top: 4px"
    >
      <card-list :api="searchCouponRecords">
        <template v-slot:default="defaultProps">
          <!-- {{ defaultProps.item }} -->
          <div class="coupon-list">
            <div class="coupon-item outTime">
              <div class="coupon-left">
                <div
                  class="left-wrapper"
                  style="
                    position: absolute;
                    top: 50%;
                    margin-top: -33px;
                    font-weight: 700;
                    text-align: center;
                    line-height: 1.2;
                    width: 108px;
                  "
                >
                  <div class="desc">
                    {{ defaultProps.item.favorablePrice }}
                    <span style="font-size: 18px">元</span>
                  </div>
                  <div class="type">
                    {{
                      defaultProps.item.couponType === "Coupon"
                        ? `优惠券`
                        : `代金券`
                    }}
                  </div>
                </div>
              </div>
              <div class="coupon-right">
                <div class="title line-ellipsis">
                  {{ defaultProps.item.couponTitle }}
                </div>
                <div class="limitDesc">请至猫眼中填写优惠券</div>
                <div class="title">
                  券号:<label
                    class="tag-read"
                    @click="copy(defaultProps.item.couponNo)"
                    >{{ defaultProps.item.couponNo }}</label
                  >
                </div>
                <div class="range-status-container">
                  <div class="range-status range-has-border">
                    有效期 {{ defaultProps.item.startEffectiveTime }} 至
                    {{ defaultProps.item.endEffectiveTime }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </card-list>
    </van-popup>
    <van-popup
      v-model:show="state.showOrder"
      position="bottom"
      style="height: 100%; padding-top: 4px"
    >
      <order-list @exit="closeOrderList"></order-list>
    </van-popup>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import useClipboard from "vue-clipboard3";
import OrderList from "./OrderList.vue";
import CardList from "@/components/CardList.vue";
import { searchCouponRecords } from "@/api/coupon.js";
import { useStore } from "vuex";
import { Toast } from "vant";

export default {
  components: { CardList, OrderList },
  setup() {
    const store = useStore();
    const openId = ref("");
    const { toClipboard } = useClipboard();
    onMounted(() => {
      openId.value = store.getters["user/getOpenId"];
    });

    const listObj = ref([
      { icon: "photo-o", text: "购物车" },
      { icon: "photo-o", text: "待付款" },
      { icon: "photo-o", text: "待发货" },
      { icon: "photo-o", text: "待收货" },
      { icon: "photo-o", text: "待评价" },
      { icon: "photo-o", text: "退款" },
      { icon: "photo-o", text: "售后" },
    ]);
    const state = reactive({
      showCoupon: false,
      showOrder: false,
      coupons: null,
    });
    const leftImgUrl = require("../../assets/order-left.png");
    const rightImgUrl = require("../../assets/order-right.png");
    const openCouponList = () => {
      console.log("openCouponList");
      state.showCoupon = true;
    };
    const openOrderList = () => {
      state.showOrder = true;
    };
    const closeOrderList = () => {
      state.showOrder = false;
    };
    const copy = async (Msg) => {
      try {
        console.log(Msg, "内容");
        //复制
        await toClipboard(Msg);
        Toast(`${Msg} 复制成功`);
        //下面可以设置复制成功的提示框等操作
        //...
      } catch (e) {
        //复制失败
        console.error(e);
      }
    };
    return {
      leftImgUrl,
      rightImgUrl,
      listObj,
      state,
      openCouponList,
      openOrderList,
      openId,
      searchCouponRecords,
      closeOrderList,
      copy,
    };
  },
};
</script>

<style lang="scss" scoped>
.body {
  height: 10.5rem;
  background: #ffffff;
}
.card-container {
  padding-top: 2rem;
}

.page-coupon-list .coupon-list {
  padding-bottom: 15px;
}
.left-wrap {
  position: absolute;
  top: 50%;
  margin-top: -33px;
  font-weight: 700;
  text-align: center;
  line-height: 1.2;
  width: 108px;
}
a,
button,
div {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.coupon-item {
  display: flex;
}
.desc {
  font-size: 35px;
}
.outTime {
  background-color: #dddddd;
}
.coupon-left {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  min-height: 125px;
  width: 108px;
  background-color: #ff9d00;
  color: #fff;
}
.coupon-left::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 5px;
  top: 0;
  left: 108px;
  background-image: linear-gradient(
      to bottom,
      #eeeeee 3px,
      transparent 3px,
      transparent
    ),
    radial-gradient(6px circle at 3px 6px, transparent 3px, #eeeeee 3px);
  background-size: 3px 9px;
}
.coupon-right {
  margin: auto;
}
.card p {
  margin-block-start: 0em;
  margin-block-end: 0em;
}
.card-info {
  padding: 1rem 0 0 1rem;
  color: #ffffff;
}
.title1 {
  font-size: 1.2rem;
  font-weight: 600;
}
.title2 {
  font-size: 0.8rem;
}
.title {
  font-size: 1rem;
  font-weight: 600;
}
.limitDesc {
  font-size: 0.75rem;
}
::v-deep .van-cell__value {
  border-radius: 8px 0 0 8px;
}
.range-status .range-has-border {
  font-size: 0.75rem;
}

.card {
  border-radius: 1rem;
  background-size: 100% 100%;
  box-shadow: 0px 16px 46px 0px rgba(28, 44, 29, 0.17);
  position: relative;
  width: 100%;
  height: 6.5rem;
}
::v-deep .van-grid-item__text {
  margin-top: 3px;
}
.van-coupon__body > img {
  width: 200px;
  height: 100px;
}
</style>