<template>
  <div class="center-content">
    <div class="title-group">
      <div class="subTitle">客服中心</div>
      <van-grid column-num="3">
        <van-grid-item
          v-for="(value, index) in listObj"
          :key="index"
          :icon="value.icon"
          :text="value.text"
          @click="value.click"
        />
      </van-grid>
    </div>
    <van-action-sheet v-model:show="show" title="拨打客服电话">
      <div class="content">
        <van-button href='tel:13900000001' :block="true" type="primary">13900000001</van-button>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  setup() {
    const listObj = ref([
      { icon: "photo-o", text: "常见问题", click: null },
      { icon: "photo-o", text: "客服电话", click: callPhone },
      { icon: "photo-o", text: "服务中心" },
    ]);
    const show = ref(false);
    function callPhone() {
      show.value = true;
    }
    return { listObj, show };
  },
};
</script>

<style lang="scss" scoped>
.center-content {
  margin-top: 0.5rem;
  background: #ffffff;
}
.title-group {
  margin-top: 0.5rem;
  height:3rem;
}
.subTitle {
  height: 3rem;
  line-height: 3rem;
  margin-left: 1.5rem;
}
.content {
  margin: 1.5rem;
  text-align: center;
}
</style>