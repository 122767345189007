<template>
  <div>
    <van-nav-bar
      left-text="返回"
      left-arrow
      title="我的订单"
      @click-left="onClickLeft"
      fixed
      placeholder
    />
    <van-tabs v-model:active="active">
      <van-tab
        v-for="(item, index) in categories"
        :title="item.title"
        :key="index"
      >
        <card-list :api="searchOrders" :apiParams="{ OrderStatus: item.value }">
          <template v-slot:default="defaultProps">
            <div class="item">
              <div class="item-img">
                <img
                  style="width: 100%; height: 100%; border-radius: 5px 0 0 5px"
                  :src="defaultProps.item.goods[0].imgUrl"
                />
              </div>
              <div class="item-desc">
                <div class="desc">
                  <p class="movie-title">
                    订单号:{{ defaultProps.item.orderNo }}
                  </p>
                  <p class="desc">
                    {{ defaultProps.item.goods[0].goodTitle }}
                  </p>
                  <p class="movie-subtitle">
                    原价:{{ defaultProps.item.originAmount }} 实际价格:{{
                      defaultProps.item.actualAmount
                    }}
                  </p>
                </div>
                <div class="on-movie">
                  {{
                    (defaultProps.item.payType = 1 ? "微信支付" : "其他支付")
                  }}
                </div>
              </div>
            </div>
          </template>
        </card-list>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { searchOrders } from "@/api/order.js";
import CardList from "@/components/CardList.vue";
import { useStore } from "vuex";
// import {} from "";
export default {
  components: { CardList },
  emits: ["exit"],
  setup(props, { emit }) {
    const openId = ref("");
    const active = ref("");
    const keyword = ref("");
    const store = useStore();
    // 0-未付款，1-待收货，2-已发货，9-已完成，8-售后/退款
    const categories = ref([
      { title: "全部", value: "" },
      {
        title: "未付款",
        value: "0",
      },
      {
        title: "待收货",
        value: "1",
      },
      {
        title: "已发货",
        value: "2",
      },
      { title: "已完成", value: "9" },
      { title: "售后/退款", value: "8" },
    ]);
    onMounted(() => {
      openId.value = store.getters["user/getOpenId"];
    });
    const onClickLeft = () => {
      emit("exit");
    };
    return { categories, active, keyword, searchOrders, onClickLeft, openId };
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: flex;
}
.item-img {
  padding: 0.5rem;
  flex: 1;
}
.item-desc {
  flex: 2;
  padding: 0.5rem;
}
p {
  text-overflow: ellipsis;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
</style>