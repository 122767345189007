<template>
  <div class="about-background">
    <about-header />
    <order />
    <address-manage />
    <customer-service-center />
    
  </div>
</template>

<script>
import AboutHeader from "./Header.vue";
import Order from "./Order.vue";
import AddressManage from "./AddressManage.vue";
import CustomerServiceCenter from "./CustomerServiceCenter.vue";
export default {
  components: {
    Order,
    AboutHeader,
    AddressManage,
    CustomerServiceCenter,
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.about-background {
  background: #f4f4f4;
}
</style>