<template>
  <div>
    <van-nav-bar title="我的地址" left-text="返回" left-arrow @click-left="onClickLeft" />
    <van-address-list
      v-model="chosenAddressId"
      :list="list"
      default-tag-text="默认"
      @add="onAdd"
      @edit="onEdit"
      @select="changeAddress"
    >
      <template #default>
        <div class="ok-btn" v-if="showOk">
          <van-button block @click="choice" round type="primary">确认</van-button>
        </div>
      </template>
    </van-address-list>
    <van-popup v-model:show="show" position="right" :style="{ height: '100%', width: '100%' }">
      <van-nav-bar :title="title" left-text="返回" left-arrow @click-left="close" />
      <van-address-edit
        :area-list="areaList"
        :address-info="addressInfo"
        show-postal
        show-delete
        show-set-default
        show-search-result
        :area-columns-placeholder="['请选择', '请选择', '请选择']"
        @save="onSave"
        @delete="onDelete"
      />
    </van-popup>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import {
  searchAddress,
  deleteCustomerAddress,
  saveAddress,
} from "@/api/about.js";
import { areaList } from "@vant/area-data";
import { Toast } from "vant";
import { useStore } from "vuex";
export default {
  emits: ["changeShow", "changeValue"],
  props: {
    value: {
      type: Object,
      default: () => {
        return null;
      },
    },
    showOkBtn: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  setup(props, { emit }) {
    const chosenAddressId = ref("1");
    const showOk = ref(false);
    const list = ref(null);
    const show = ref(false);
    const addressInfo = ref(null);
    const title = ref(null);
    const openId = ref("");
    const addressModel = ref({});
    const store = useStore();
    onMounted(() => {
      console.log(props.showOkBtn, "showOkBtn");
      showOk.value = props.showOkBtn;
      load();
    });
    const load = () => {
      openId.value = store.getters["user/getOpenId"];
      searchAddress({ openId: openId.value }).then((res) => {
        // let isDefault = true;
        list.value = res.data.dataList.map((res) => {
          let item = {
            id: res.addressId,
            name: res.consigneeName,
            tel: res.mobile,
            address: res.address,
            areaCode: res.areaCode,
            isDefault: res.isDefault,
          };
          // isDefault = false;
          return item;
        });
      });
    };
    const onClickLeft = () => {
      emit("changeShow");
    };
    const onAdd = () => {
      title.value = "新增地址";
      addressInfo.value = {
        id: "",
        name: "",
        tel: "",
        address: "",
        areaCode: "",
        isDefault: false,
      };
      show.value = true;
    };
    const close = () => {
      show.value = false;
    };
    //     id	每条地址的唯一标识	number | string
    // name	收货人姓名	string
    // tel	收货人手机号	number | string
    // address	收货地址	string
    // isDefault	是否为默认地址	boolean
    const onEdit = (item) => {
      title.value = "编辑地址";
      console.log(item, "onEdititem");
      addressInfo.value = item;
      console.log(addressInfo.value, "info");
      show.value = true;
    };
    const onSave = (item) => {
      addressModel.value = {
        addressId: item.id,
        openId: openId.value,
        province: item.province,
        city: item.city,
        area: item.county,
        address: `${item.city}${item.province}${item.county}${item.addressDetail}`,
        isDefault: item.isDefault,
        postal: item.postalCode,
        mobile: item.tel,
        consigneeName: item.name,
      };
      saveAddress(addressModel.value).then((res) => {
        Toast(res.msg);
        load();
        show.value = false;
        addressModel.value = {};
      });
    };
    const choice = () => {
      // console.log(addressModel.value);
      emit("changeValue", addressModel.value);
      emit("changeShow");
    };
    const changeAddress = (item) => {
      addressModel.value = {
        addressId: item.id,
        openId: openId.value,
        address: `${item.address}`,
        isDefault: item.isDefault,
        mobile: item.tel,
        consigneeName: item.name,
      };
    };
    const onDelete = () => {
      console.log(
        {
          addressId: addressInfo.value.id,
          openId: openId.value,
        },
        "log"
      );
      deleteCustomerAddress({
        addressId: addressInfo.value.id,
        openId: openId.value,
      }).then((res) => {
        Toast(res.msg);
        show.value = false;
        load();
      });
    };
    return {
      chosenAddressId,
      list,
      onAdd,
      onEdit,
      showOk,
      areaList,
      show,
      addressInfo,
      choice,
      changeAddress,
      title,
      close,
      onClickLeft,
      onDelete,
      onSave,
    };
  },
};
</script>

<style lang="scss" scoped>
.ok-btn {
  position: fixed;
  bottom: 50px;
  left: 0;
  z-index: 999;
  box-sizing: border-box;
  width: 100%;
  padding: 0 16px;
  background-color: #fff;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
</style>