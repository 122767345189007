<template>
  <div>
    <div class="about-backgroud" :style="{ backgroundImage: 'url(' + urlIcon + ')' }">
      <h2 class="about-title">我的</h2>
      <div class="avatar">
        <van-image
          round
          width="3.5rem"
          height="3.5rem"
          style="background-color: #fff"
          :src="userInfo.avatar"
        />
        <div class="user-info">
          <p class="login-title" v-if="userInfo.isLogin">{{ userInfo.nickName }}</p>
          <p class="login-title" v-else @click="goToUserInfo">点击登录</p>
          <p
            class="phone"
            v-if="userInfo.isLogin && userInfo.mobile === null || userInfo.mobile === ''"
            @click="bindPhoneNumber"
          >绑定手机 ></p>
          <p v-else>{{ userInfo.mobile }}</p>
        </div>
      </div>
    </div>
    <van-popup v-model:show="bindPhoneShow" style="width: 100%" position="bottom" round>
      <van-form>
        <van-field
          v-model="mobile"
          name="phone"
          label="手机号"
          placeholder="手机号"
          :rules="[{ required: true, message: '请填写手机号' }]"
        />
        <div style="margin: 16px">
          <van-button round block type="primary" @click="submitPhone">提交</van-button>
        </div>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { wechatAuth } from "@/api/wechat.js";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  setup() {
    const urlIcon = require("../../assets/about-banner.png");
    const userInfo = ref({
      avatar: "",
      businessCode: null,
      customerId: "",
      isLogin: true,
      mobile: "绑定手机>",
      nickName: "立即登录",
      openId: "",
    });
    const mobile = ref(null);
    const bindPhoneShow = ref(false);
    const store = useStore();
    const isAuth = false;
    onMounted(() => {
      userInfo.value = computed(() => store.getters["user/getUserInfo"]).value;

      console.log(userInfo.value, "userinfo");
    });
    const router = useRouter();

    function goToUserInfo() {
      // router;
      wechatAuth().then((res) => {
        if (res.code === 200) {
          console.log(res.data.url, "url");
          router.push({
            path: "/outlink",
            query: {
              link: res.data.url,
            },
          });
        }
      });
      // ...get user info api
      return;
    }
    function submitPhone() {
      store.dispatch("user/setMobile", mobile.value);

      bindPhoneShow.value = false;
    }
    function bindPhoneNumber() {
      bindPhoneShow.value = true;
    }
    return {
      urlIcon,
      userInfo,
      isAuth,
      mobile,
      goToUserInfo,
      bindPhoneShow,
      bindPhoneNumber,
      submitPhone,
    };
  },
};
</script>

<style lang="scss" scoped>
.about-backgroud {
  height: 10rem;
  background-size: 100% 100%;
}
.about-title {
  text-align: center;
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-size: 1rem;
  color: #ffffff;
  padding-top: 0.5rem;
}
.avatar {
  padding-left: 2rem;
  padding-top: 1.8rem;
  display: flex;
  position: relative;
}
.user-info {
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  color: #ffffff;
}
.avatar p {
  margin-block-start: 0em;
  margin-block-end: 0em;
}
.login-title {
  font-size: 1rem;
}
.phone {
  font-size: 1rem;
}
::v-deep .van-image .van-image--round {
  background-color: #ffffff;
}
::v-deep .van-image__img,
.van-image__error,
.van-image__loading {
  width: 85%;
  height: 85%;
  margin: 0 auto;
  margin-top: 7.5%;
}
</style>