import { post } from "@/utils/http.js";

export const searchOrders = (params) => {
    return post("/Customer/SearchOrders", params);
};

export const cancelOrder = (params) => {
    return post("/Customer/CancelOrder", params);
};

export const searchOrderInfo = (params) => {
    return post("/Customer/SearchOrderInfo", params);
};

export const modifyBusinessCode = (params) => {
    return post("/Customer/ModifyBusinessCode", params);
};

export const hideCouponRecord = (params) => {
    return post("/Customer/HideCouponRecord", params);
};

export const submitOrder = (params) => {
    return post("/Customer/SubmitOrder", params);
};