<template>
  <div class="address-bg">
    <div>
      <h4 class="subTitle" style="float: left">地址管理</h4>
    </div>
    <div
      class="subTitle"
      style="float: right; margin-right: 1rem; font-size: 0.8rem"
      @click="openAddressList"
    >
      查看更多 >
    </div>
    <van-popup
      v-model:show="show"
      position="right"
      :style="{ height: '100%', width: '100%' }"
    >
      <address-list v-model="show" @changeShow="openAddressList" />
    </van-popup>
  </div>
</template>

<script>
import { ref } from "vue";

import AddressList from "./AddressList.vue";
// import { useRouter } from "vue-router";
export default {
  components: { AddressList },
  setup() {
    // const router = new useRouter();
    const show = ref(false);
    const openAddressList = () => {
      show.value = !show.value;
    };
    return { openAddressList, show };
  },
};
</script>

<style lang="scss" scoped>
.address-bg {
  margin-top: 0.5rem;
  background: #ffffff;
  height: 3rem;
}
h4 {
  margin-left: 1.5rem;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.subTitle {
  height: 3rem;
  line-height: 3rem;
}
</style>