import { post } from "@/utils/http.js";

export const searchCoupons = (params) => {
  return post("/coupon/SearchCoupons", params);
};

export const gainCouponProcess = (params) => {
  return post("/wechat/GainCouponProcess", params);
};

export const searchCouponInfo = (params) => {
  return post("/coupon/SearchCouponInfo", params);
};

export const searchCouponTypeCount = (params) => {
  return post("/Customer/SearchCouponTypeCount", params);
};

export const searchCouponRecords = (params) => {
  return post("/Customer/SearchCouponRecords", params);
};
